<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Based on the balanced reaction below, determine the mass of oxygen required to completely
        react with
        <number-value :value="sampleMass" unit="\text{g}" />
        of propane,
        <chemical-latex content="C3H8" />
        .
      </p>

      <p class="mb-4 pl-6">
        <chemical-latex content="C3H8(g) + 5 O2(g) -> 3 CO2(g) + 4 H2O(l)" />
      </p>

      <calculation-input
        v-model="inputs.massOxygen"
        prepend-text="$\text{Mass of Oxygen Gas:}$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';

export default {
  name: 'Question67',
  components: {
    NumberValue,
    ChemicalLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        massOxygen: null,
      },
    };
  },
  computed: {
    sampleMass() {
      return this.taskState.getValueBySymbol('sampleMass').content;
    },
  },
};
</script>
